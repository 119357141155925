<template>
  <div>
    <el-card>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button size="small" type="primary" @click="addClick" v-permission="['sys:productCategory:add']"
            >添加分类
          </el-button>
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '12px 0'
          }"
          :cell-style="{ padding: '12px 0' }"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column property="id" label="#" width="50" align="center" />
          <el-table-column property="name" label="产品分类名称" />
          <el-table-column property="details" label="产品分类介绍" />
          <el-table-column property="sortNumber" label="排序号" width="70" />
          <el-table-column property="createTime" label="创建时间" width="160" />
          <el-table-column fixed="right" label="操作" width="100">
            <template #default="scope">
              <el-button type="text" size="small" @click="editClick(scope.row)" v-permission="['sys:productCategory:update']"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                style="color: #ff4d4f"
                @click="delClick(scope.row)"
                v-permission="['sys:productCategory:delete']"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <add-product-category
      v-if="addDialogVisible"
      v-model="addDialogVisible"
      @close="getListData"
    >
    </add-product-category>

    <edit-product-category
      v-if="editDialogVisible"
      v-model="editDialogVisible"
      :id="selectItemId"
      @close="getListData"
    >
    </edit-product-category>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {
  getProductCategoryList,
  deleteProductCategoryById
} from '@/api/product-category'
import AddProductCategory from './components/AddProductCategory'
import EditProductCategory from './components/EditProductCategory'
import { ElMessage, ElMessageBox } from 'element-plus'
/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  tableData.value = await getProductCategoryList()
  loading.value = false
}
getListData()

/**
 * 选择要操作的列表id
 */
const selectItemId = ref(0)

/**
 * 显示添加弹出框
 */
const addDialogVisible = ref(false)
const addClick = () => {
  addDialogVisible.value = true
}

/**
 * 修改点击事件
 * @param row
 */
const editDialogVisible = ref(false)
const editClick = row => {
  selectItemId.value = row.id
  editDialogVisible.value = true
}

/**
 * 删除字典项
 */
const delClick = row => {
  ElMessageBox.confirm('确认删除 [' + row.name + '] 分类吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteProductCategoryById(row.id)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}
</script>

<style scoped></style>
