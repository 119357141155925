<template>
  <el-dialog
    :model-value="modelValue"
    width="500px"
    title="添加产品分类"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :rules="rules" :label-width="100">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入分类名称"></el-input>
      </el-form-item>
      <el-form-item label="分类介绍" prop="details">
        <el-input
          v-model="form.details"
          type="textarea"
          placeholder="请输入分类介绍"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序号" prop="sortNumber">
        <el-input
          v-model="form.sortNumber"
          placeholder="请输入排序号"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { addProductCategory } from '@/api/product-category'

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'close'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  name: '',
  details: '',
  sortNumber: 1
})

// 验证规则
const rules = ref({
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '产品分类名称不能为空'
    }
  ],
  details: [
    {
      required: false,
      trigger: 'blur',
      message: '产品分类介绍不能为空'
    }
  ],
  sortNumber: [
    {
      required: true,
      trigger: 'blur',
      message: '排序号不能为空'
    }
  ]
})

const loading = ref(false)

const onSubmitClick = () => {
  formRef.value.validate(async valid => {
    if (!valid) return

    loading.value = true
    await addProductCategory(form.value)
    ElMessage.success('添加成功')
    loading.value = false
    closed()
  })
}
</script>

<style lang="scss" scoped></style>
